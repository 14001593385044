<template>
  <b-card class="overflow-hidden" :body-class="currentBreakPoint === 'xs' ? 'p-0 py-50' : 'p-1'">
    <b-custom-tabs :tabs="tabs" lazy-once />
  </b-card>
</template>

<script>
import assignmentConfirms from "./assignmentConfirms.vue";
import assignmentHistory from "./assignmentHistory.vue";
import TrainingRequestList from "./TrainingRequestList.vue";

export default {
  data() {
    return {
      tabs: [
        {
          title: "Onay Bekleyenler",
          component: assignmentConfirms,
        },
        {
          title: "Atama Geçmişi",
          component: assignmentHistory,
        },
        {
          title: "Eğitim Talepleri",
          component: TrainingRequestList,
        },
      ],
    };
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters["app/currentBreakPoint"];
    },
  },
};
</script>

<style></style>

<template>
  <app-overlay>
    <db-searchable-table
      v-model="filters"
      @changed="getData"
      @row-clicked="showDetails"
      class="border mb-50"
      :items="trainings"
      :total-rows="total"
      :fields="fields"
      hover
      searchable
    >
      <template #actions="{ item }"> <app-icon-button @click="deleteTraining(item)" v-if="!item.isStarted" variant="gradient-danger" icon="TrashIcon" /> </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import TrainingDetailModal from "../ISG/trainings/TrainingDetailModal/TrainingDetailModal.vue";

export default {
  data() {
    return {
      filters: {},
    };
  },
  computed: {
    trainings() {
      return this.$store.getters.trainings?.data ?? [];
    },
    total() {
      return this.$store.getters.trainings?.total ?? 0;
    },
    physicianId() {
      return this.$store.getters.user?.physician?._id;
    },
    fields() {
      return [
        { key: "company.name", label: "Firma" },
        { key: "branch.name", label: "Sicil" },
        { key: "subject", label: "Eğitim Konusu" },
        { key: "trainingDate", label: "Eğitim Tarihi", formatDate: true },
        { key: "actions", label: "İşlemler" },
      ];
    },
  },
  methods: {
    getData() {
      this.$store.dispatch("getTrainings", {
        query: {
          ...this.filters,
          searchFields: this.filters.search ? JSON.stringify(["subject"]) : null,
          instructor: this.physicianId,
        },
        actionType: "setTrainings",
      });
    },
    showDetails(item) {
      this.$axios
        .get("/trainings/get-one", { params: { _id: item?._id }, loading: "table" })
        .then((result) => {
          this.$showAppModal({ title: "Eğitim Detayları", component: TrainingDetailModal, centered: false, size: "custom", item: result?.data });
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
        });
    },
    deleteTraining(item) {
      this.$confirm({ message: "Eğitimi silmek istediğinize emin misiniz?" }, () => {
        this.$axios
          .delete(`/trainings/${item?._id}`, { loading: "table" })
          .then((result) => {
            this.$store.commit("deleteTraining", item?._id);
            this.$emitter.$emit("Notification", { variant: "success", title: "İşlem Başarılı", message: result?.data?.message ?? null });
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
          });
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<template>
  <div>
    <assignment-confirm-tabs />
  </div>
  <!-- <b-row>
    <b-col lg="9">
    </b-col>
  </b-row> -->
</template>

<script>
import assignmentConfirms from "./assignmentConfirms.vue";
import assignmentConfirmTabs from "./assignmentConfirmTabs.vue";

export default {
  components: {
    assignmentConfirms,
    assignmentConfirmTabs,
  },
};
</script>

<style></style>
